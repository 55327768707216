import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Revision } from '../../shared/types/revision';
import { Category, Perk, Product, RecommendedTile } from '@shared';
import { RevisionTypes, RoutePaths } from '@shared/constants';

@Component({
  selector: 'app-revision-table',
  templateUrl: './revision-table.component.html',
  styleUrls: ['./revision-table.component.scss'],
})
export class RevisionTableComponent implements OnChanges {
  @Output() revisionNav = new EventEmitter<any>();
  perkRevision: Revision | undefined;

  @Input() revisions: Revision[] = [];
  @Input() perkRevisions: Revision[] = [];

  filteredRevisions: Revision[] = [];

  dateSort = false;
  editorSort = false;
  commentSort = false;
  searchText = '';

  pathMap: { [key: string]: string } = {
    categories: RoutePaths.ViewCategory,
    products: RoutePaths.ViewProduct,
    perks: RoutePaths.ViewPerk,
    recommendedTiles: RoutePaths.ViewRecommendedTile,
  };

  getRouterLink(type: string): string {
    return this.pathMap[type] || '/';
  }
  navigateToRevision(revision: Revision) {
    const filteredPerkRevisions = this.perkRevisions.filter((rev) => rev.created < revision.created);

    const sortedPerkRevisions = filteredPerkRevisions.sort((a, b) => b.created - a.created);
    this.perkRevision = sortedPerkRevisions[0];

    const emitData: any = {};

    if (revision.updatedObject) {
      emitData.object = revision.updatedObject as Category | Product | Perk | RecommendedTile;
    } else {
      emitData.object = revision.originalObject as Category | Product | Perk | RecommendedTile;
    }

    if (revision.type === RevisionTypes.RecommendedTiles) {
      emitData.perkObject = this.perkRevision?.updatedObject as Perk;
    }
    this.revisionNav.emit(emitData);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.filteredRevisions = this.revisions;
  }
  sortDate() {
    const value = this.dateSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => a.lastUpdated - b.lastUpdated);
      this.dateSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => b.lastUpdated - a.lastUpdated);
      this.dateSort = false;
    }
  }

  sortEditor() {
    const value = this.editorSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() > b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() < b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = false;
    }
  }

  sortComment() {
    const value = this.commentSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() > b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() < b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = false;
    }
  }

  filterRevisionsBySearchText() {
    if (this.searchText === '') {
      this.filteredRevisions = this.revisions;
    }
    this.filteredRevisions = this.revisions.filter((revision) => {
      return (
        revision.revisionComment.toLowerCase().includes(this.searchText.toLowerCase()) ||
        revision.userName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }
}
