import { Perk } from '@shared';
import { Icon } from './icon';

export interface Product {
  id: string;
  productName: string;
  subtext: string;
  icon: Icon;
  enabled: boolean;
  effectiveDate: string;
  rolloutPercentage: number;
  displayInMobile: boolean;
  displayInWeb: boolean;
  categoryId: string;
  orderOfDisplay: number;
  userName: string;
  userId: string;
  perks: Perk[];
  revisionComment?: string;
}
