export const IIQ_LINK = 'https://iiq.nwie.net/';

export const RATED_STATES_SAVE = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Washington DC', abbreviation: 'DC' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const RATED_STATES = [
  'All States',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington DC',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const TENURE_VALUES = [
  { value: 0, comparison: 'N/A', display: 'N/A' },
  { value: 1, comparison: '<', display: 'less than 1 year' },
  { value: 1, comparison: '>=', display: '1 or more years' },
  { value: 2, comparison: '<', display: 'less than 2 years' },
  { value: 2, comparison: '>=', display: '2 or more years' },
  { value: 3, comparison: '<', display: 'less than 3 years' },
  { value: 3, comparison: '>=', display: '3 or more years' },
  { value: 4, comparison: '<', display: 'less than 4 years' },
  { value: 4, comparison: '>=', display: '4 or more years' },
  { value: 5, comparison: '<', display: 'less than 5 years' },
  { value: 5, comparison: '>=', display: '5 or more years' },
  { value: 6, comparison: '<', display: 'less than 6 years' },
  { value: 6, comparison: '>=', display: '6 or more years' },
  { value: 7, comparison: '<', display: 'less than 7 years' },
  { value: 7, comparison: '>=', display: '7 or more years' },
  { value: 8, comparison: '<', display: 'less than 8 years' },
  { value: 8, comparison: '>=', display: '8 or more years' },
  { value: 9, comparison: '<', display: 'less than 9 years' },
  { value: 9, comparison: '>=', display: '9 or more years' },
  { value: 10, comparison: '<', display: 'less than 10 years' },
  { value: 10, comparison: '>=', display: '10 or more years' },
];

export const HOME_AGE_VALUES = [
  { lowerValue: undefined, upperValue: undefined, title: 'N/A' },
  { lowerValue: 20, upperValue: undefined, title: '20 years & older' },
  { lowerValue: 30, upperValue: undefined, title: '30 years & older' },
  { lowerValue: 40, upperValue: undefined, title: '40 years & older' },
];

export const POLICIES_REQUIRED = [
  {
    name: 'Auto',
  },
  {
    name: 'Home',
  },
  {
    name: 'Pet',
  },
  {
    name: 'Renters',
  },
  {
    name: 'Umbrella',
  },
  {
    name: 'Life',
  },
  {
    name: 'Dwelling Fire',
  },
  {
    name: 'Condo',
  },
];

export const ICONS = [
  { type: 'name', data: 'Car' },
  { type: 'name', data: 'House' },
  { type: 'name', data: 'Fire' },
  { type: 'name', data: 'Handshake' },
  { type: 'name', data: 'Key' },
  { type: 'name', data: 'Lock' },
  { type: 'name', data: 'Paw' },
  { type: 'name', data: 'Wallet' },
  { type: 'name', data: 'Heart' },
  { type: 'name', data: 'Calendar' },
  { type: 'name', data: 'Shield' },
  { type: 'name', data: 'Receipt' },
  { type: 'name', data: 'Customerservice' },
  { type: 'name', data: 'Moneyinhand' },
  { type: 'name', data: 'Prescription' },
];

export enum MobileSectionNames {
  Tile = 'Tile',
  Perks = 'Perks',
  Rewards = 'Rewards',
}

export const MOBILE_SECTIONS = [
  {
    name: MobileSectionNames.Tile,
    display: true,
  },
  {
    name: MobileSectionNames.Perks,
    display: true,
  },
  {
    name: MobileSectionNames.Rewards,
    display: true,
  },
];

export enum RoutePaths {
  ViewCategory = '/category/view',
  ViewProduct = '/product/view',
  ViewPerk = '/perk/view',
  ViewRecommendedTile = '/recommended/view',
  EditCategory = '/category/edit',
  EditProduct = '/product/edit',
  EditPerk = '/perk/edit',
  EditRecommendedTile = '/recommended/edit',
  CreateCategory = '/create/category',
  CreateProduct = '/create/product',
  CreatePerk = '/create/perk',
  CreateRecommendedTile = '/create/recommended',
}

export enum RevisionTypes {
  Perks = 'perks',
  Categories = 'categories',
  RecommendedTiles = 'recommendedTiles',
  Products = 'products',
}
