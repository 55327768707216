import { Icon } from './icon';
import { Rule } from './rule';

export interface RecommendedTile {
  id: string;
  created: number;
  lastUpdated: number;
  navigationId: number;
  recommendedTileName: string;
  description: string;
  image: Icon;
  perkId: string;
  enabled: boolean;
  effectiveDate: string;
  userName: string;
  userId: string;
  revisionComment?: string;
}
