import { AfterViewChecked, Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { CategoryService } from '@services/category.service';
import { PerkService } from '@services/perk.service';
import { ProductService } from '@services/product.service';
import { RecommendedService } from '@services/recommended.service';
import { Category } from '../../shared/types/categories';
import { Perk } from '../../shared/types/perks';
import { Product } from '../../shared/types/products';
import { RecommendedTile } from '../../shared/types/recommendedTile';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements AfterViewChecked, OnInit, OnDestroy {
  @Output() nav = new EventEmitter<{ object: Category | Product | Perk | RecommendedTile }>();
  @Output() urlPath = new EventEmitter<string>();

  categories: Category[] | undefined = undefined;
  products: Product[] | undefined = undefined;
  perks: Perk[] | undefined = undefined;
  recommendedTiles: RecommendedTile[] | undefined = undefined;
  readOnly = true;
  categoryDisabled = false;
  subscriptions = new Subscription();

  constructor(
    private categoryService: CategoryService,
    private productService: ProductService,
    private perkService: PerkService,
    private recommendedService: RecommendedService,
    private router: Router,
  ) {}

  public checkUpdate = true;

  async ngOnInit() {
    this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
    if (this.checkUpdate) {
      this.checkUpdate = false;
    }

    this.categories = await firstValueFrom(this.categoryService.$categories);
    this.categoryService.checkCategoryDisabled(this.categories);

    this.products = await firstValueFrom(this.productService.$products);
    this.perks = await firstValueFrom(this.perkService.$perks);
    this.recommendedTiles = await firstValueFrom(this.recommendedService.$recommendedTiles);

    this.subscriptions.add(
      this.categoryService.categoryDisabled$.subscribe((value) => {
        this.categoryDisabled = value;
      }),
    );
  }

  navigate(object: Category | Product | Perk | RecommendedTile, path: string) {
    this.nav.emit({ object: object });
    this.urlPath.emit(path);
    this.router.navigateByUrl(`/${path}/view`, { state: object });
  }

  @ViewChildren('accordion') accordion?: QueryList<any>;
  @ViewChildren('button') button?: QueryList<any>;

  ngAfterViewChecked() {
    this.accordion?.forEach((accordion) => {
      const style = document.createElement('style');
      style.innerHTML = '.bolt-accordion-wc--content { margin: 0 !important; }';
      accordion?.nativeElement.shadowRoot.appendChild(style);
    });
    this.button?.forEach((button) => {
      const style = document.createElement('style');
      style.innerHTML =
        '.bolt-button-wc { justify-content: left !important; white-space: normal; text-overflow: ellipsis; display: block; overflow: hidden }';
      button?.nativeElement.shadowRoot.appendChild(style);
    });
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
