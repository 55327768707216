import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subscription, map, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
import { Category } from '../shared/types/categories';
import { MobileSectionNames, MOBILE_SECTIONS } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CategoryService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  public clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  public $categories = this.getCategories();
  private subscriptions: Subscription = new Subscription();
  private categoryDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public categoryDisabled$: Observable<boolean> = this.categoryDisabledSubject.asObservable();
  public sections = MOBILE_SECTIONS;

  getCategoryDisabled(): boolean {
    return this.categoryDisabledSubject.value;
  }

  setCategoryDisabled(value: boolean): void {
    this.categoryDisabledSubject.next(value);
  }

  checkCategoryDisabled(categories: Category[]): void {
    let isTileCategoryPresent = false;
    let isPerksCategoryPresent = false;
    let isRewardsCategoryPresent = false;

    categories?.forEach((category) => {
      switch (category.mobileSection) {
        case MobileSectionNames.Tile:
          if (category.enabled) {
            isTileCategoryPresent = true;
          }
          break;
        case MobileSectionNames.Perks:
          if (category.enabled) {
            isPerksCategoryPresent = true;
          }
          break;
        case MobileSectionNames.Rewards:
          if (category.enabled) {
            isRewardsCategoryPresent = true;
          }
          break;
        default:
          break;
      }
    });

    if (isTileCategoryPresent && isPerksCategoryPresent && isRewardsCategoryPresent) {
      this.setCategoryDisabled(true);
    } else {
      this.setCategoryDisabled(false);
    }
  }

  saveCategory(category: Category, revisionComment: string): Observable<Category> {
    this.getUserId(category);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          if (category.id) {
            category.revisionComment = revisionComment;
            return this.http.put<Category>(this.url + `/categories/${category.id}`, category, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          } else {
            return this.http.post<Category>(this.url + '/categories', category, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          }
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getCategories(): Observable<Category[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http
            .get<Category[]>(this.url + '/categories', {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            })
            .pipe(map((categories) => categories.sort((a, b) => (a.categoryName.toUpperCase() > b.categoryName.toUpperCase() ? 1 : -1))));
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  deleteCategory(category: Category): Observable<Category> {
    this.getUserId(category);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.delete<Category>(this.url + `/categories/${category.id}`, {
            body: category,
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getUserId(category: Category) {
    this.subscriptions.add(
      this.oidcSecurityService.getUserData().subscribe((userData) => {
        category.userName = userData.displayName;
        category.userId = userData.userId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
