<div class="bolt-space-bottom-3xl">
  <bolt-accordion *ngIf="!readOnly; else displayAlert" header="+ Create New" class="bolt-background-vibrant-blue" #accordion>
    <div style="display: flex; align-items: baseline">
      <bolt-button
        [disabled]="this.categoryDisabled"
        [ngStyle]="{ width: categoryDisabled ? 'auto' : '100%' }"
        type="primary"
        href="/create/category"
        #button
      >
        <div class="bolt-body-copy-lg">Category</div>
      </bolt-button>
      <bolt-contextual-help *ngIf="this.categoryDisabled" slot="help" type="push" heading="Required">
        Remove an enabled Category with Mobile Section to create new Category.
      </bolt-contextual-help>
    </div>
    <bolt-button width="full" type="primary" href="/create/product" #button><div class="bolt-body-copy-lg">Product</div></bolt-button>
    <bolt-button width="full" type="primary" href="/create/perk" #button><div class="bolt-body-copy-lg">Perks & Rewards</div></bolt-button>
    <bolt-button width="full" type="primary" href="/create/recommended" #button><div class="bolt-body-copy-lg">Recommended for you</div></bolt-button>
  </bolt-accordion>
</div>

<ng-template #displayAlert>
  <bolt-notification type="info"
    >You can only view the details. To make changes, request the owner for edit access in <a [href]="link">IIQ</a></bolt-notification
  >
</ng-template>

<div *ngIf="categories && products && perks && recommendedTiles">
  <div class="bolt-container-fluid">
    <div class="bolt-row">
      <div class="bolt-col accordion-width">
        <bolt-accordion-group>
          <bolt-accordion header="Categories" active="true" #accordion>
            <bolt-button width="full" type="tertiary" *ngFor="let category of categories" (click)="navigate(category, 'category')" #button
              >{{ category.categoryName }}
            </bolt-button>
          </bolt-accordion>
        </bolt-accordion-group>
      </div>
      <div class="bolt-col accordion-width">
        <bolt-accordion-group>
          <bolt-accordion header="Products" active="true" #accordion>
            <bolt-button
              class="bolt-button-wc"
              width="full"
              type="tertiary"
              *ngFor="let product of products"
              (click)="navigate(product, 'product')"
              #button
              >{{ product.productName }}</bolt-button
            >
          </bolt-accordion>
        </bolt-accordion-group>
      </div>
      <div class="bolt-col accordion-width">
        <bolt-accordion-group>
          <bolt-accordion header="Perks & Rewards" active="true" #accordion>
            <bolt-button width="full" type="tertiary" *ngFor="let perk of perks" (click)="navigate(perk, 'perk')" #button
              >{{ perk.perkName }}
            </bolt-button>
          </bolt-accordion>
        </bolt-accordion-group>
      </div>
      <div class="bolt-col accordion-width">
        <bolt-accordion-group>
          <bolt-accordion header="Recommended for you" active="true" #accordion>
            <bolt-button
              width="full"
              type="tertiary"
              *ngFor="let recommendedTile of recommendedTiles"
              (click)="navigate(recommendedTile, 'recommended')"
              #button
              >{{ recommendedTile.recommendedTileName }}
            </bolt-button>
          </bolt-accordion>
        </bolt-accordion-group>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!categories || !products || !perks || !recommendedTiles">
  <bolt-waiting-indicator>Loading...</bolt-waiting-indicator>
</div>
