import { DatePipe } from '@angular/common';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationResponse } from './types';

export function shouldDisplayError(control: AbstractControl, type?: string): boolean {
  if (control?.dirty === false) {
    return false;
  }

  if ((type !== undefined && control.hasError(type)) || type === undefined) {
    return true;
  }

  return false;
}

const currentDate = new Date();
export const defaultMaxDate = new DatePipe('en-US').transform(currentDate.setFullYear(currentDate.getFullYear() + 1), 'MM/dd/yyyy');
export function effectiveDateValidator(control: AbstractControl): ValidationErrors | null {
  if (!shouldDisplayError(control)) {
    return null;
  }

  const effectiveDateValue = control.value || '';
  if (!effectiveDateValue) {
    return { required: true, message: 'This field is required' };
  }

  const dateRegex = new RegExp(/^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/i);
  const isValidDate = dateRegex.test(effectiveDateValue);
  if (!isValidDate) {
    return { invalidDate: true, message: 'Invalid date' };
  } else {
    const effectiveDate = new Date(effectiveDateValue);
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    const max = new Date(defaultMaxDate as string);
    if (effectiveDate < todaysDate) {
      return { minDate: true, message: 'The date must not be in the past' };
    } else if (effectiveDate > max) {
      return { maxDate: true, message: 'The date must not be more than one year in the future' };
    }
  }

  return null;
}

export function effectiveDateMatchValidator(perkDateValue: string | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!shouldDisplayError(control)) {
      return null;
    }

    const recommendedEffectiveDateValue = control.value || '';
    const recommendedEffectiveDate = new Date(recommendedEffectiveDateValue);

    if (perkDateValue) {
      const perkDate = new Date(perkDateValue);

      if (recommendedEffectiveDate < perkDate) {
        return { dateMatch: true, message: 'Recommended For You effective date cannot be before perk date' };
      }
    }

    return null;
  };
}

export function imageUploadValidator(file: File, width: number, height: number): ValidationResponse {
  /* Check the following:
   ** File type SVG
   ** File size less than 500 KB
   ** File name only letters, numbers, underscores, and hyphens
   ** Image width between 1500-2500 PX
   ** Resolution less than 120 MP
   ** Display error if file does not meet requirements "Please upload a valid image."
   */

  const SVG_CONTENT_TYPE = 'image/svg+xml';
  const ALLOWED_TYPES = [SVG_CONTENT_TYPE];
  const MAX_SIZE = 500 * 1024; // 500 KB
  const MAX_WIDTH = 2500;
  const MIN_WIDTH = 1500;

  const MAX_RESOLUTION = 120 * 1000000; // 120MP in pixels.

  const fileNameRegex = new RegExp(/^([a-zA-Z]+|_+|-+|\d+[^\W+])+$/);

  const returnValue: ValidationResponse = { valid: true, issues: [] };

  if (!file) {
    returnValue.valid = false;
  }

  if (file.size > MAX_SIZE) {
    returnValue.issues.push('File size must be less than 500 KB');
    returnValue.valid = false;
  }

  if (!ALLOWED_TYPES.includes(file.type)) {
    returnValue.issues.push('Unsupported File Type');
    returnValue.valid = false;
  }

  //The following will be valid if we ever re-add jpg support. For now, it'll just always skip.
  if (returnValue.valid && file.type !== SVG_CONTENT_TYPE) {
    if (width < MIN_WIDTH || width > MAX_WIDTH) {
      returnValue.issues.push(`Width  must be between 1500 and 2500 pixels, current image is ${width}`);
      returnValue.valid = false;
    }

    if (width * height > MAX_RESOLUTION) {
      returnValue.issues.push('Resolution Must be less than 120 MP');
      returnValue.valid = false;
    }
  }

  const fileName = file.name.slice(0, file.name.lastIndexOf('.'));

  if (!fileNameRegex.test(fileName)) {
    returnValue.issues.push('File name can only contain letters, numbers, underscores, and hyphens');
    returnValue.valid = false;
  }

  return returnValue;
}

export function rolloutPercentageValidator(control: AbstractControl): ValidationErrors | null {
  if (!shouldDisplayError(control)) {
    return null;
  }

  const rolloutPercentageValue = control.value || '0';
  if (!rolloutPercentageValue) {
    return { required: true, message: 'This field is required' };
  }

  const rolloutRegex = new RegExp(/^\d+$/);
  const validRollout = rolloutRegex.test(rolloutPercentageValue);
  if (validRollout && rolloutPercentageValue <= 100 && rolloutPercentageValue >= 1) {
    return null;
  } else {
    return { range: true, message: 'Please choose a valid number between 1 and 100' };
  }
}

export function locationValidator(locationFormGroup: AbstractControl): ValidationErrors | null {
  if (!shouldDisplayError(locationFormGroup)) {
    return null;
  }

  if (Object.values((locationFormGroup as FormGroup).controls).some((c: AbstractControl) => c.value === true)) {
    return null;
  } else {
    return { required: true, message: 'One of these fields is required' };
  }
}

export function urlValidatorFactory(
  nationwideUrlCondition: () => boolean | null,
  nationwideUrlError: string,
): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !shouldDisplayError(control)) {
      return null;
    }

    let regEx = new RegExp(/^https?:\/\/\w*[^/\s]\.[\S]*$/i);
    if (!regEx.test(control.value)) {
      return { urlFormat: true, message: 'This field cannot contain blank spaces and must begin with http:// or https://' };
    }

    if (nationwideUrlCondition()) {
      regEx = new RegExp(/^https:\/\/\w*[^/\s]\.nationwide\.com[\S]*$/i);
      if (!regEx.test(control.value)) {
        return { urlFormat: true, message: nationwideUrlError };
      }
    }

    return null;
  };
}

export function requiredIfFactory(condition: () => boolean | null, errorMessage: string): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!shouldDisplayError(control)) {
      return null;
    }

    if (
      condition() &&
      (!control.value || (control.value.trim && !control.value.trim()) || (control.value.length !== undefined && control.value.length === 0))
    ) {
      return { required: true, message: errorMessage };
    }

    return null;
  };
}

export function revisionValidator(control: AbstractControl): ValidationErrors | null {
  if (!shouldDisplayError(control)) {
    return null;
  }

  if (control.value.length) {
    if (control.value.length > 100) {
      return { maxCharacters: true, message: 'Comment must be less than 100 characters' };
    }
    if (control.value.length < 15) {
      return { minCharacters: true, message: 'Comment must be more than 15 characters' };
    }
  }

  const revisionCommentValue = control.value || '';

  if (!revisionCommentValue) {
    return { required: true, message: 'This field is required' };
  }

  const revisionRegex = new RegExp(/\p{Alphabetic}|\p{Number}/u);
  const isValidComment = revisionRegex.test(revisionCommentValue);
  if (!isValidComment) {
    return { invalidComment: true, message: 'Comment must contain letters and numbers' };
  } else {
    return null;
  }
}
export function perkIdValidator(control: AbstractControl): ValidationErrors | null {
  const perkIdValue = control.value;

  if (Object.keys(perkIdValue).length == 0 && control.dirty) {
    return { required: true, message: 'Please make a selection.' };
  }
  return null;
}
